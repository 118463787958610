import React, { useState  , useRef} from 'react'
import { useDreamTourismContext } from '../../../context/DreamTourismContext'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [name , setName] = useState("")
  const [email , setEmail] = useState("")
  const [phone , setPhone] = useState("")
  const [message , setMessage] = useState("")

  const form = useRef()

  const {
    setNotiStatus, 
    setNotiError,
    setNotiContent,
  } = useDreamTourismContext()

  const handleContactForm = (e) => {
    console.log(form);
    e.preventDefault()
    if(name && email && phone && message){

        emailjs.sendForm('service_3jadtpj', 'template_afuuw0r', form.current, 'G8pZ66Pxeqg_g2Fdo')
        .then((result) => {
            console.log(result);
            if(result.text === "OK"){       
                setNotiContent("Email Sent Successfully")     
                setNotiStatus(true);
                setNotiError(false)
                setTimeout(() =>{
                      setNotiStatus(false);
                      setNotiError(false)
                },4000)
                setEmail("")
                setMessage("")
                setName("")
                setPhone("")
            }else{
                setNotiContent("Email not sent")
                setNotiStatus(true);
                setNotiError(true)
            }
        }, (error) => {
            console.log(error.text);
            setNotiStatus(true);
            setNotiError(false)
            setTimeout(() =>{
                  setNotiStatus(false);
            },4000)
        });

    }else{
        setNotiContent("Please Fill All The Feild")
        setNotiStatus(true);
        setNotiError(false)
        setTimeout(() =>{
              setNotiStatus(false);
        },4000)
    }
  }


  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="black-section-tiitle section-title">
          <h2>Contact</h2>
          <h3><span>Contact Us</span></h3>
          <p>Call us or message and we'll respond as soon as possible.</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>Level 26 East Tower,World Trade Center, Colombo 01, Sri Lanka</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box mb-4">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>info@dreamdtourism.com</p>
              <p>dreamdtourism@outlook.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box mb-4">
              <i className="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <p>+94 11 350 3803</p>
              <p>+94 77 904 3802</p>
              <p>+94 77 904 3979</p>
            </div>
          </div>

        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-6">
            <iframe title="Google Maps" className="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15842.273404342921!2d79.8552066!3d6.9420775!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2597820f0f24d%3A0xcaff1aa7d3a27700!2sDream%20Destination%20Tourism%20(PVT)%20Ltd!5e0!3m2!1sen!2s!4v1729329718483!5m2!1sen!2s" frameBorder="0" style={{ border: '0', width: '100%', height: '384px' }} allowFullScreen></iframe>
          </div>
        
          <div  className="col-lg-6">
            <form ref={form} onSubmit={(e) => handleContactForm(e)} className="php-email-form">
              <div className="row">
                <div className="col form-group">
                  <input onChange={(e) => setName(e.target.value)} type="text" value={name}  name="from_name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col form-group">
                  <input onChange={(e) => setEmail(e.target.value)} type="email" value={email} className="form-control" name="reply_to" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group">
                <input onChange={(e) => setPhone(e.target.value)} type="text" value={phone} className="form-control" name="phone_num" id="phone" placeholder="Phone Num" required />
              </div>
              <div className="form-group">
                <textarea onChange={(e) => setMessage(e.target.value)} value={message} className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
  );
}

export default Contact;
